import Vue from 'vue';
import util from "./isUtil";
import { SECRET_KEY,IV } from '@/store/mutation-types'
import Crypto from "crypto-js";

/**
 * 判断是否数据是否为空
 * @param any '任何数据'
 * @return undefined, null ,'', NaN, [], {}, 0, false 为 true，其他为 false
 */
Vue.prototype.isEmpty = (any) => {
    if (util.isTrueEmpty(any)) return true;
    if (util.isRegExp(any)) {
        return false;
    } else if (util.isDate(any)) {
        return false;
    } else if (util.isError(any)) {
        return false;
    } else if (util.isArray(any)) {
        return any.length === 0;
    } else if (util.isString(any)) {
        return any.length === 0;
    } else if (util.isNumber(any)) {
        return any === 0;
    } else if (util.isBoolean(any)) {
        return !any;
    } else if (util.isObject(any)) {
        for (const key in any) {
            return false && key;
        }
        return true;
    }
    return false;
}

/**
 * 判断是否为手机号
 * @param str
 * @returns {boolean}
 */
Vue.prototype.isMobile = (str) => {
    let mobileReg = /^[1][2-9][0-9]{9}$/;
    return mobileReg.test(str);
}

/**
 * 判断是否为身份证号
 * @param idNumber
 * @returns {boolean}
 */
Vue.prototype.isIDCardNumber = (idNumber) => {
    if (idNumber.length !== 18) {
        return false;
    }
    const year = parseInt(idNumber.substring(6, 10));
    const month = parseInt(idNumber.substring(10, 12));
    const day = parseInt(idNumber.substring(12, 14));
    if (isNaN(year) || isNaN(month) || isNaN(day)) {
        return false;
    }
    const date = new Date(year, month - 1, day);
    if (date.getFullYear() !== year || date.getMonth() + 1 !== month || date.getDate() !== day) {
        return false;
    }
    // 验证最后一位校验码
    const weights = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2];
    const checkCodes = ['1', '0', 'X', '9', '8', '7', '6', '5', '4', '3', '2'];
    let sum = 0;
    for (let i = 0; i < 17; i++) {
        sum += parseInt(idNumber.charAt(i)) * weights[i];
    }
    const checkCode = checkCodes[sum % 11];
    return idNumber.charAt(17).toUpperCase() === checkCode;
}

/**
 * 秒数转换时间
 * @param value 120
 * @param type hour、minute
 * @returns {string} type=hour：00:00:00 type=minute：00:00:00
 */
Vue.prototype.secondToTime = (value,type = 'hour') => {
    var hour = 0, minute = 0, second = 0;

    if(type === "hour"){
        hour = parseInt(value /  60 / 60);
        minute = parseInt(value / 60 % 60);
        second = parseInt(value % 60);

        hour = hour < 10 ? '0' + hour : hour;
        minute = minute < 10 ? '0' + minute : minute;
        second = second < 10 ? '0' + second : second;
        return hour + ':' + minute + ':' + second;
    }
    if(type === "minute") {
        minute = parseInt(value / 60);
        second = parseInt(value % 60);

        minute = minute < 10 ? '0' + minute : minute;
        second = second < 10 ? '0' + second : second;
        return hour + ':' + minute + ':' + second;
    }
}

// 隐藏手机号中间四位
Vue.prototype.mobileCenterHide = (mobile) => {
    var reg = /^(\d{3})\d{4}(\d{4})$/;
    return mobile.replace(reg,'$1****$2');
}

Vue.prototype.trim = (str) => {
  return String.prototype.trim.call(str);
}

export function isEmpty(any) {
    if (util.isTrueEmpty(any)) return true;
    if (util.isRegExp(any)) {
        return false;
    } else if (util.isDate(any)) {
        return false;
    } else if (util.isError(any)) {
        return false;
    } else if (util.isArray(any)) {
        return any.length === 0;
    } else if (util.isString(any)) {
        return any.length === 0;
    } else if (util.isNumber(any)) {
        return any === 0;
    } else if (util.isBoolean(any)) {
        return !any;
    } else if (util.isObject(any)) {
        for (const key in any) {
            return false && key;
        }
        return true;
    }
    return false;
}

// 加密
export function encryptData(data) {
    return Crypto.AES.encrypt(data, Crypto.enc.Utf8.parse(SECRET_KEY), {
        iv: Crypto.enc.Utf8.parse(IV),
        mode: Crypto.mode.CBC,
        padding: Crypto.pad.Pkcs7
    }).toString();
}

// 解密
export function decryptData(data){
    return Crypto.AES.decrypt(data, Crypto.enc.Utf8.parse(SECRET_KEY), {
        iv: Crypto.enc.Utf8.parse(IV),
        mode: Crypto.mode.CBC,
        padding: Crypto.pad.Pkcs7
    }).toString(Crypto.enc.Utf8);
}
