import Vue from 'vue'
import App from './App.vue'
import store from '@/store/'
import router from "@/router/router"
import VueStorage from 'vue-ls'
import ElementUI from 'element-ui'
import '@/assets/theme/index.css';
import 'animate.css';

import '@/utils/permission' // permission control
import '@/utils/util'
import { VueAxios } from '@/utils/request'

import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.min.css'

import IndexHeader from '@/components/indexheader.vue';

Vue.config.productionTip = false
Vue.component("IndexHeader",IndexHeader)
Vue.use(ElementUI)
Vue.use(VueStorage, {
  namespace: 'zhongXun__', // key prefix
  name: 'ls', // name variable Vue.[ls] or this.[$ls],
  storage: 'local' // storage name session, local, memory
})
Vue.use(VueAxios)
Vue.use(VueAwesomeSwiper, /* { default global options } */)
new Vue({
  render: h => h(App),
  store,
  router
}).$mount('#app')
