import Vue from 'vue'
import { axios } from '@/utils/request'
import { ACCESS_TOKEN } from '@/store/mutation-types'

const user = {
  state: {
    token: '',
    mobile:'',
    username: '',
    dept_name:'',
    user_expire_date:'',
    avatar: '',
    loginStatus: 0,
    info: {}
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_USERNAME: (state, { username }) => {
      state.username = username
    },
    SET_MOBILE: (state, { mobile }) => {
        state.mobile = mobile
    },
    SET_DEPT_NAME: (state, { dept_name }) => {
        state.dept_name = dept_name
    },
    SET_USER_EXPIRE_DATE: (state, { user_expire_date }) => {
        state.user_expire_date = user_expire_date
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar
    },
    SET_LOGIN_STATUS: (state,info) => {
      state.loginStatus = info
    },
    SET_INFO: (state, info) => {
      state.info = info
    },
    UPDATE_TOKEN: (state,info) => {
      Vue.ls.set(ACCESS_TOKEN, info, 7 * 24 * 60 * 60 * 1000)
    }
  },

  actions: {
    // 登录
    Login ({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        axios({
          url: '/user/login',
          method: 'post',
          data: userInfo
        }).then(res => {
          if(res.errno === 0){
            Vue.ls.set(ACCESS_TOKEN, res.data, 7 * 24 * 60 * 60 * 1000)
            commit('SET_TOKEN', res.data)
            commit('SET_LOGIN_STATUS', 1);
          }
          resolve(res)
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 获取用户信息
    GetInfo ({ commit }) {
      return new Promise((resolve, reject) => {
        return axios({
          url: '/user/info',
          method: 'post',
          headers: {
            'Content-Type': 'application/json;charset=UTF-8'
          }
        }).then(response => {
          if(response.errno === 0){
            const result = response.data
            commit('SET_LOGIN_STATUS',1)
            commit('SET_USERNAME', { username: result.username })
            commit('SET_MOBILE', { mobile: result.mobile })
            commit('SET_DEPT_NAME', { dept_name: result.dept_name })
            commit('SET_USER_EXPIRE_DATE', { dept_name: result.user_expire_date })
            commit('SET_INFO', result)
            resolve(result)
          }else reject(response.errmsg)
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 登出
    Logout ({ commit }) {
      return new Promise((resolve) => {
        return axios({
          url: '/user/logout',
          method: 'get',
          headers: {
            'Content-Type': 'application/json;charset=UTF-8'
          }
        }).then(() => {
          commit('SET_TOKEN', '')
          commit('SET_LOGIN_STATUS', 0)
          Vue.ls.remove(ACCESS_TOKEN);
          resolve();
        })
      })
    }

  }
}

export default user
