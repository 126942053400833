<template>
	<div class="indexheader">
		<div class="lefts">
			<img src="@/assets/images/indexheader_logo.png" alt="" @click="toIndex">
		</div>
		<div class="rights">
			<div class="les" @click="toUser()">
				<img src="@/assets/images/indexheader_name.png" alt="">
				<div class="names">{{ username }}</div>
			</div>
			<div class="ris" @click="outLogin">退出登录</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
	name: 'IndexHeader',
	// props: {
	// 	username: {
	// 		type: String,
	// 		default: ""
	// 	}
	// },
	computed:{
		...mapGetters(["username"])
	},
	methods: {
		outLogin() {
			this.$confirm('确定要退出当前账号？', '提示', {
				closeOnClickModal: true,
				closeOnPressEscape: true,
				type: 'info'
			}).then(() => {
				this.$store.dispatch('Logout').then(() => {
					this.$router.push('/login');
				}).catch(() => {});
			}).catch(() => {});
		},
		toIndex() {
			this.$router.push({name: 'IndexHome'})
		},
		//个人中心
		toUser() {
			this.$router.push({name: 'UserView'})
		}
	}
}
</script>

<style>
.indexheader {
	width: 100%;
	height: 64px;
	padding: 0 42px;
	box-sizing: border-box;
	background: #fff;
	display: flex;
	justify-content: space-between;
	align-content: center;
}

.indexheader .lefts {
	display: flex;
	align-items: center;
}

.indexheader .lefts img {
	cursor: pointer;
}

.indexheader .rights {
	display: flex;
	align-content: center;
	align-items: center;
}

.indexheader .rights .les {
	min-width: 108px;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	cursor: pointer;
}

.indexheader .rights .les::after {
	content: '';
	width: 1px;
	height: 22px;
	background: #deeffe;
	position: absolute;
	right: 0;
	top: calc(50% - 11px);

}

.indexheader .rights .les::before {
	content: '';
	width: 1px;
	height: 22px;
	background: #deeffe;
	position: absolute;
	left: 0;
	top: calc(50% - 11px);
}

.indexheader .rights .les img {
	width: 32px;
	height: 32px;
	border-radius: 50%;
	display: block;
}

.indexheader .rights .les .names {
	color: #333333;
	font-size: 14px;
	padding-left: 6px;
}

.indexheader .rights .ris {
	width: 124px;
	height: 40px;
	border-radius: 3px;
	text-align: center;
	line-height: 40px;
	color: #ffffff;
	font-size: 16px;
	background: #5c80ff;
	margin-left: 20px;
	cursor: pointer;
}
</style>
