import Vue from 'vue'
import router from '@/router/router'
import store from '@/store'

import NProgress from 'nprogress' // progress bar
import '@/components/NProgress/nprogress.css' // progress bar custom style
// import { Notification } from 'element-ui';
import { setDocumentTitle, domTitle } from '@/utils/domUtil'
import { ACCESS_TOKEN } from '@/store/mutation-types'

NProgress.configure({ showSpinner: false }) // NProgress Configuration

const whiteList = ['/login'] // no redirect whitelist

router.beforeEach((to, from, next) => {
  NProgress.start() // start progress bar
  to.meta && (typeof to.meta.title !== 'undefined' && setDocumentTitle(`${to.meta.title} - ${domTitle}`))
  if (Vue.ls.get(ACCESS_TOKEN)) {
    /* has token */
    if (to.path === '/login') {
      next({ name: 'IndexHome',replace:true })
      NProgress.done()
    } else {
      if (store.getters.loginStatus === 0) {
        store.dispatch('GetInfo').then(() => {
          const redirect = decodeURIComponent(from.query.redirect || to.path);
          if (to.path === redirect) {
            next({ ...to, replace: true })
          } else {
            // 跳转到目的路由
            next({ path: redirect })
          }
        }).catch(() => {
          // Notification({
          //   title: '错误',
          //   message: '请求用户信息失败，请重新登录！',
          //   type:"error"
          // })
          // store.dispatch('Logout').then(() => {
          //   next({ path: '/login', query: { redirect: to.fullPath } })
          // })
        }).finally(() => {
          NProgress.done()
        })
      } else {
        store.dispatch('GetInfo').then(() => {
          next()
        }).catch(() => {
          // Notification({
          //   title: '错误',
          //   message: '请求用户信息失败，请重新登录！',
          //   type:"error"
          // })
          // store.dispatch('Logout').then(() => {
          //   next({ path: '/login', query: { redirect: to.fullPath } })
          // })
        })
      }
    }
  } else {
    if (whiteList.includes(to.path)) {
      // 在免登录白名单，直接进入
      next()
    } else {
      next({ path: '/login', query: { redirect: to.fullPath } })
      NProgress.done() // if current page is login will not trigger afterEach hook, so manually handle it
    }
  }
})

router.afterEach(() => {
  NProgress.done() // finish progress bar
})
