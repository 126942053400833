 import Vue from "vue";
 import VueRouter from "vue-router";

 //插入注册组件
 Vue.use(VueRouter);
//2定义路由
const routes = [
    {
        path:'/',//首页
        name:'IndexHome',
        meta:{title:"企业内部培训服务平台"},
        component: () => import('@/view/index.vue')
    },
    {
        path:'/login',//登录
        name:'IndexLogin',
        meta:{title:"登录"},
        component: () => import('@/view/login.vue')
    },
    {
        path:'/chapter',//章节练习
        name:'ChapterHome',
        meta:{title:"章节练习"},
        component: () => import('@/view/questions/chapter.vue')
    },
    {
        path:'/chapterTop',//精品章节
        name:'ChapterTop',
        meta:{title:"精品章节"},
        component: () => import('@/view/questions/chapterTop.vue')
    },
    {
        path:'/practice/:type/:id',// 练习模式-答题
        name:'PracticeExam',
        meta:{title:"章节练习"},
        component: () => import('@/view/questions/practice.vue')
    },
    {
        path:'/parsing/:type/:id',// 解析模式
        name:'ParsingReply',
        meta:{title:"答题解析"},
        component: () => import('@/view/questions/parsing.vue')
    },
    {
        path:'/historic',//历年真题
        name:'HistoricHome',
        meta:{title:"历年真题"},
        component: () => import('@/view/questions/historic.vue')
    },
    {
        path:'/paper',//精品试卷
        name:'PaperHome',
        meta:{title:"精品试卷"},
        component: () => import('@/view/questions/paper.vue')
    },
    {
        path:'/pattern/:origin/:id',// 模式选择
        name:'HistoricalOrPaperPattern',
        meta:{title:"模式选择"},
        component: () => import('@/view/questions/pattern.vue')
    },
    {
        path:'/exam/:examId',// 在线考试
        name:'ExamHome',
        meta:{title:"在线考试"},
        component: () => import('@/view/questions/exam.vue')
    },
    {
        path:'/examReport/:examId',//考试报告
        name:'ExamReport',
        meta:{title:"考试报告"},
        component: () => import('@/view/questions/exam_report.vue')
    },
    {
        path:'/examParsing/:examId',//考试报告
        name:'ExamParsing',
        meta:{title:"考试解析"},
        component: () => import('@/view/questions/exam_parsing.vue')
    },
    {
        path:'/mock', // 全真模考
        name:'MockHome',
        meta:{title:"全真模考"},
        component: () => import('@/view/questions/mock.vue')
    },
    {
        path:'/course',// 课程中心
        name:'CourseHome',
        meta:{title:"课程中心"},
        component: () => import('@/view/course/index.vue')
    },
    {
        path:'/course/class/:id',// 班型目录
        name:'CourseClass',
        meta:{title:"班型目录"},
        component: () => import('@/view/course/class.vue')
    },
    {
        path:'/course/list/:cid',//课程目录
        name:'CourseList',
        meta:{title:"课程目录"},
        component: () => import('@/view/course/list.vue')
    },
    {
        path:'/course/play/:cid/:course_id',//视频播放
        name:'CoursePlay',
        meta:{title:"视频播放"},
        component: () => import('@/view/course/play.vue')
    },
    {
        path:"/user",
        name:"UserHome",
        component: () => import('@/view/user/conmon.vue'),
        redirect: '/user/index',
        children: [
            {
                name:"UserView",
                meta: { title: '观看记录' },
                path:"/user/index",
                component: () => import('@/view/user/index.vue'),
            },
            {
                name:"UserErrorQuestions",
                meta: { title: '错题库' },
                path:"/user/errorQuestions/:type?",
                component: () => import('@/view/user/errorsQuestions.vue'),
            },
            {
                name:"UserProblem",
                meta: { title: '做题记录' },
                path:"/user/problem/:type?",
                component: () => import('@/view/user/problem.vue'),
            },
            {
                name:"UserCollect",
                meta: { title: '收藏记录' },
                path:"/user/collect/:type?",
                component: () => import('@/view/user/collect.vue'),
            },
            {
                name:"UserNotes",
                meta: { title: '我的笔记' },
                path:"/user/notes/:type?",
                component: () => import('@/view/user/notes.vue'),
            },
            {
                name:"UserMyInformation",
                meta: { title: '个人资料' },
                path:"/user/myinfromation",
                component: () => import('@/view/user/myinfromation.vue'),
            },
        ]
    },
    // 未匹配到路由，跳转至404页面
    // {
    //     path:'/404',//404
    //     name:'NotFound',
    //     meta:{title:"404 - 未知页面"},
    //     component: () => import('@/view/404.vue')
    // },
    { path: '/:pathMatch(.*)*', name:'NotFound', meta:{title:"404 - 未知页面"},component: () => import('@/view/404.vue') }
];

// 解决报错：Uncaught (in promise)
const originalPush = VueRouter.prototype.push
const originalReplace = VueRouter.prototype.replace
// 针对 push 方法
VueRouter.prototype.push = function push (location, onResolve, onReject) {
    if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
    return originalPush.call(this, location).catch(err => err)
}
// 针对 replace 方法
VueRouter.prototype.replace = function push (location, onResolve, onReject) {
    if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject)
    return originalReplace.call(this, location).catch(err => err)
}

export default new VueRouter({
    mode: 'history',
    routes,
    scrollBehavior: () => ({ y: 0 }),
})
