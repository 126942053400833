import Vue from 'vue'
import axios from 'axios'
import store from '@/store'
import {isEmpty, encryptData, decryptData} from "./util"
import {MessageBox} from 'element-ui';
import {VueAxios} from './axios'
import {ACCESS_TOKEN} from '@/store/mutation-types'
import router from "@/router/router";

// 创建 axios 实例
const service = axios.create({
	// baseURL: "http://localhost:3510", // api base_url
	baseURL: "https://classapi.zhongxunwx.com", // api base_url
	responseType: "text",
	timeout: 15000 // 请求超时时间
})

// request interceptor
service.interceptors.request.use(config => {
	const token = Vue.ls.get(ACCESS_TOKEN)
	if (!isEmpty(token)) config.headers['authorization'] = `Bearer ${token}`; // 让每个请求携带自定义 token 请根据实际情况自行修改
	if (process.env.NODE_ENV === "development") console.log("request：", config.data);
	if(!isEmpty(config.data)) config.data = {req: encryptData(JSON.stringify(config.data))}
	return config
})

// response interceptor
service.interceptors.response.use((response) => {
	const data = JSON.parse(decryptData(response.data));
	if (process.env.NODE_ENV === "development") console.log("response：", data);
	return data;
}, async (error) => {
	if (error.response) {
		const data = JSON.parse(decryptData(error.response.data));
		if (error.response.status === 403) {
			MessageBox.alert(data.errmsg,'Forbidden',{
				confirmButtonText:"确定",
				callback:() => {
					store.dispatch('Logout').then(() => {
						router.replace("/login");
					})
				}
			})
			return false;
		}
		if (error.response.status === 401) {
			Vue.ls.set(ACCESS_TOKEN, data.data, 7 * 24 * 60 * 60 * 1000);
			store.commit("SET_TOKEN",data.data);
			store.commit("SET_LOGIN_STATUS",1);
			return service(error.response.config);
		}
	}
	return Promise.reject(error)
})

const installer = {
	vm: {},
	install(Vue) {
		Vue.use(VueAxios, service)
	}
}

export {
	installer as VueAxios,
	service as axios
}
