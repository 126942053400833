import Vue from 'vue'
import Vuex from 'vuex'

import user from './modules/user'

Vue.use(Vuex)

export default new Vuex.Store({
	modules: {
		user
	},
	state: {},
	mutations: {},
	actions: {},
	getters:{
		userInfo: state => state.user.info,
		loginStatus: state => state.user.loginStatus,
		username: state => state.user.username,
		mobile: state => state.user.mobile,
		dept_name: state => state.user.dept_name
	}
})
